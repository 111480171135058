import { Navigate, Route, Routes } from 'react-router-dom';
import { lazy } from 'react';
import AccauntManagement from '../views/AccauntManagement';
import StatisticList from '../views/AccauntManagement/StatisticList';

const KYC = lazy(async () => await import('../views/KYC'));
const ErrorPage = lazy(async () => await import('../views/Error'));
const RootLayout = lazy(async () => await import('../components/Layout'));
const Login = lazy(async () => await import('../views/Login'));
const Registration = lazy(async () => await import('../views/Registration'));
const ResetPassword = lazy(async () => await import('../views/ResetPassword'));
const ResetPasswordSuccess = lazy(async () => await import('../views/ResetPasswordSuccess'));
// const PageNotFound = lazy(() => import('../views/PageNotFound'));
const ProtectedAuthRoute = lazy(async () => await import('./ProtectedAuthRoute'));
const TestScreen = lazy(async () => await import('../views/TestScreen'));
const ForgotPassword = lazy(async () => await import('../views/ForgotPassword'));
const Exchange = lazy(async () => await import('../views/Exchange'));
const Orders = lazy(async () => await import('../views/Orders'));
const OrederDetail = lazy(async () => await import('../views/Order'));
const Support = lazy(async () => await import('../views/Support'));

const RouterApp = (): JSX.Element => (
  <Routes>
    {/* Защищенные роуты от авторизации */}
    <Route
      element={
        <ProtectedAuthRoute>
          <RootLayout />
        </ProtectedAuthRoute>
      }
      errorElement={<ErrorPage />}
    >
      <Route
        path="/exchange"
        element={<Exchange />}
      />
      <Route
        path="/orders/:id"
        element={<OrederDetail />}
      />
      <Route
        path="/kyc"
        element={<KYC />}
      />
      <Route
        path="/support"
        element={<Support />}
      />
      <Route
        path="/orders"
        element={<Orders />}
      />
      <Route
        path="/settings"
        element={<AccauntManagement />}
      />
      <Route
        path={'/settings/statistic-list'}
        element={<StatisticList />}
      />
    </Route>
    {/* Общие роуты */}
    <Route
      path="/login"
      element={<Login />}
      errorElement={<ErrorPage />}
    />
    <Route
      path="/registration"
      element={<Registration />}
      errorElement={<ErrorPage />}
    />
    <Route
      path="/forgot"
      element={<ForgotPassword />}
      errorElement={<ErrorPage />}
    />
    <Route
      path="/reset-password"
      element={<ResetPassword />}
      errorElement={<ErrorPage />}
    />
    <Route
      path="/reset-password/success"
      element={<ResetPasswordSuccess />}
      errorElement={<ErrorPage />}
    />
    <Route
      path="/test"
      element={<TestScreen />}
    />
    <Route
      path="*"
      element={
        <Navigate
          to={localStorage.getItem('accessToken') ? '/exchange' : '/login'}
          replace
        />
      }
    />
  </Routes>
);

export default RouterApp;
